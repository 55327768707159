import React from "react";
import Layout from "../layouts/index.jsx";

import Footer from "../layouts/footer";
import Head from "../layouts/Head/Head.js";

import ContactPageContainer from "../containers/contact";
import ScrollToTop from "../components/scroll-to-top";
import './Contact1.css'
import ContactForm from "../components/contact-form/index.jsx";

const ContactPage = () => {
    return (
        <React.Fragment>
            <Layout>
            
                <div className="wrapper" >
                    <Head />
                 
<div className="head-con">


<div        
        data-aos="fade-up"
        data-aos-duration="1200">


  <ContactForm/>
  </div>
</div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ContactPage;
