import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const ContactForm = () => {
  const form = useRef();
  const [error, setError] = useState(null);

  const [name, setName] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [message, setmessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !message) {
      toast.error("Please Fill All Fields");
    } else {
      emailjs
        .sendForm(
          "service_4qtczt3",
          "template_ica17pt",
          form.current,
          "QSr5I40lPKeITR8ca"
        )
        .then(
          toast.success("Successfully Submit"),
          setName(""),
          setemail(""),
          setphone(""),
          setmessage("")
        );
    }
  };

  return (
    <>
      <section class="contact-us-sec" id="contact-section">
        <form
          ref={form}
          onSubmit={sendEmail}
          
        >
          <div class="section-heading">
            <h4>Contact us</h4>
          </div>

          <div class="inputField">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-contact1"
              type="text"
              name="name"
              id="name"
              placeholder="Your name"
              autocomplete="on"
              required
            />
            <span class="valid_info_name"></span>
          </div>
          <div class="inputField">
            <input
              value={email}
              onChange={(e) => setemail(e.target.value)}
              className="input-contact1"
              type="email"
              name="email"
              id="email"
              placeholder="Your name"
              autocomplete="on"
              required
            />
            <span class="valid_info_name"></span>
          </div>
          <div class="inputField">
            <input
              value={phone}
              onChange={(e) => setphone(e.target.value)}
              className="input-contact1"
              type="text"
              name="phone"
              placeholder="Your number"
              required=""
            />
            <span class="valid_info_email"></span>
          </div>

          <div class="inputField">
            <textarea
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              className="text-area-c"
              name="message"
              id="message"
              placeholder="Your message"
            ></textarea>
            <span class="valid_info_message"></span>
          </div>
          <div className="btn-div-send">
            <button
              onClick={sendEmail}
              type="submit"
              id="form-submit"
              className="btn-sendfrom"
            >
              Send a message
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default ContactForm;
