import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header/index.jsx";
import IntroContainer from "../containers/home/intro/index.jsx";

import TeamContainer from "../containers/home/team/index.jsx";


import Footer from "../layouts/footer/index.jsx";
import ScrollToTop from "../components/scroll-to-top/index.jsx";
import Head from "../layouts/Head/Head.js";
import './Home2.css'
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min.js";


const HomePage = () => {


  return (
    <React.Fragment>
      <Layout>
        <div className="wrapper home-default-wrapper">
          {/* <Header /> */}
          <Head/>
          <div className="main-content site-wrapper-reveal">
            {/* <IntroContainer /> */}
            <div className="home-2">
                <img src={require('../assets/img/back-bg-home.png')} alt="" />

                <div className="content-head-home">
                    <h1 className="top-h1">Top Quality Products, Highly Secure Authentication</h1>
                    <p className="top-p1">
                    Every product of ours has a unique code inside the sealed box. Nobody can copy what we offer. In short, you get the most authentic health supplements. Because we dislike fake products as much as you do.
                    </p>
                    <Link
                        
                                to={"/check-authenticity"}
                           
                            >
                                               <button  className="check-auth">Authenticate Now</button>

                            </Link>
                </div>
            </div>

            <TeamContainer />
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default HomePage;
