import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row row11">
          <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="widget-item">
              <div className="about-widget">
                <img
                  className="logi2i"
                  src={require("../../assets/img/LOGO.jpg")}
                  alt=""
                />

                <ul className="widget-contact-info">
                  <li className="info-mail">
                    <i className="icofont-email"></i>
                    <a href="mailto:support@Medicareenhancement.com">
                      support@Medicareenhancement.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
 
 
          <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-3">
            <div className="widget-item">
              {/* <h4 className="widget-title line-bottom">Newsletter</h4> */}
              {/* <div className="widget-newsletter">
                <p>
                  Sign up for our mailing list to get latest updates and offers.
                </p>
             
              </div> */}
              {/* <div className="widget-social-icons">
                                <SocialIcon
                                    path="https://twitter.com/"
                                    icon="icofont-twitter"
                                />
                                <SocialIcon
                                    path="https://myaccount.google.com/"
                                    icon="icofont-google-plus"
                                />
                                <SocialIcon
                                    path="https://www.pinterest.com/"
                                    icon="icofont-pinterest"
                                />
                                <SocialIcon
                                    path="https://rss.com/"
                                    icon="icofont-rss"
                                />
                                <SocialIcon
                                    path="https://www.facebook.com/"
                                    icon="icofont-facebook"
                                />
                                <SocialIcon
                                    path="https://dribbble.com/"
                                    icon="icofont-dribbble"
                                />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row text-center">
            <div className="col-sm-12">
              <div className="widget-copyright">
                <p>
                  &copy; 2023 Medicare Enhancement . All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
