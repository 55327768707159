import Logo from "../../components/logo";
import MainMenu from "../../components/menu/main-menu";

import { Fragment, useEffect, useState } from "react";

import Mobilenav from "../../components/mobilenav/Mobilenav";

const Header = () => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(!ofcanvasShow);
    };
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    useEffect(() => {
        const header = document.querySelector(".sticky-header");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = ({}) => {
        setScroll(window.scrollY);
    };


    return (
        <Fragment>
            <header className="header">
                <Mobilenav />

                <div
                    className="header-bottom d-none d-lg-block"
                    style={{ padding: "20px " }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="header-logo ">
                                        <Logo
                                            image={`${process.env.PUBLIC_URL}/img/logo.jpg`}
                                        />
                                    </div>
                                    <MainMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`header-bottom sticky-header d-none d-lg-block ${
                        scroll > headerTop ? "sticky" : ""
                    }`}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="header-logo ">
                                        <Logo
                                                                       image={`${process.env.PUBLIC_URL}/img/logo.jpg`}

                                        />
                                    </div>
                                    <MainMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    );
};

export default Header;
