import React from "react";
import Layout from "../layouts/index.jsx";
import Head from "../layouts/Head/Head.js";

import PageTitleContainer from "../containers/global/page-title/index.jsx";
import Footer from "../layouts/footer";
import AboutContainer from "../containers/about";
import ScrollToTop from "../components/scroll-to-top";

const AboutPage = () => {
  return (
    <>
      <div className="wrapper">
        <Head />
        {/* <div
          className="main-content site-wrapper-reveal"
          style={{ background: "black" }}
        >
          <div style={{ background: "black" }}>
            <h1
              style={{ marginBottom: "260px" }}
              data-aos="fade-up"
              className="head-pro"
            >
              Coming Soon
            </h1>
          </div>
        </div> */}
        <AboutContainer/>
        <Footer />
        <ScrollToTop />
      </div>
    </>
  );
};

export default AboutPage;
