import React, { useEffect } from 'react'
import './Head.css'
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { useState } from 'react';
import Mobilenav from '../../components/mobilenav/Mobilenav';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';


import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { HiMenuAlt2 } from 'react-icons/hi';


const StyledOffCanvas = styled.aside`
    position: fixed;
    top: 0;
    left: ${(props) => (props.isOpen ? 0 : "-100%")};
    width: 300px;
    height: 100%;
    background:black;
    z-index: 500;
    color: white;
    transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Head = () => {


    const [show, setShow] = useState(false);

    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
      const [isOpen, setIsOpen] = useState(false);
      const [products, setproducts] = useState(false);

      const Icon =
        isOpen?IoMdClose:HiMenuAlt2
      
  return (
    <div>
        

<div className={show?'head2-data2 fadeInDown1':'head2-data'}>
    <div className='logo-head-2'>
    <NavLink
                 
                        to="/"
                    >
                        <img src={require('../../assets/img/logo2.jpg')} alt="" />

                    </NavLink>
    </div>
    <div className='window-link'>

    <nav>
            <ul className="main-menu">
                <li className="">
                    <NavLink
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        exact
                        to="/"
                    >
                        Home
                    </NavLink>
                </li>

                <li>
                    <span 
                    style={{cursor:'pointer'}}
                    className="main-menu-link">Products</span>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                activeStyle={{
                                    borderBottom: "solid 2px black",
                                }}
                                className="sub-menu-link"
                                to={"/Injectable"}
                            >
                                Injectable
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={"/Oral"}
                                activeStyle={{
                                    borderBottom: "solid 2px black",
                                }}
                            >
                                Oral
                            </NavLink>
                        </li>
             
                    </ul>
                </li>
                <li>
                    <NavLink
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/Company-Profile"}
                    >
                        Company Profile
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        activeStyle={{
                            borderBottom: "solid 2px #fff",
                          
                        }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/check-authenticity"}
                    >
check-authenticity                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/Contact"}
                    >
                        Contact
                    </NavLink>
                </li>
             
            </ul>
        </nav>
    </div>
    <div className='mobile-nav'>
<Icon onClick={()=> setIsOpen(!isOpen)} size={30} color='white'/>

    </div>

</div>


<StyledOffCanvas isOpen={isOpen}>
                                                <div className="head-mobile-menu">
                                                    <ul
                                                        className="ul-menu-mobile"
                                                        style={{
                                                            listStyle: " none",
                                                        }}
                                                    >
                                                        <li>
                                                            <div className="mobile-k-m">
                                                                <Link to="/User">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="link-k"
                                                                    ></span>
                                                                </Link>
                                                            </div>
                                                        </li>

                                                        <ul
                                                            style={{
                                                                listStyle:
                                                                    " none",
                                                            }}
                                                            className="mobile-tab-ul"
                                                        >
                                                            <li className="list-font-ss">
                                                                <Link
                                                                    exact
                                                                    to="/"
                                                                >
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        Home
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    setproducts(
                                                                        !products
                                                                    )
                                                                }
                                                                className="list-font-ss"
                                                            >
                                                                <div className="di-12">
                                                                    <span
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        className="creative_link linko"
                                                                    >
                                                                        Products
                                                                    </span>
                                                                    <span className="updown-spa">
                                                                        {products ? (
                                                                            <MdOutlineKeyboardArrowUp />
                                                                        ) : (
                                                                            <MdOutlineKeyboardArrowDown />
                                                                        )}
                                                                    </span>
                                                                </div>

                                                                {products && (
                                                                    <ul className="ul-pros">
                                                                        <li>
                                                                            <Link
                                                                                to={
                                                                                    "/Injectable"
                                                                                }
                                                                            >
                                                                                <span
                                                                                    onClick={() =>
                                                                                        setIsOpen(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    className="creative_link linko"
                                                                                >
                                                                                    Injectable
                                                                                </span>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link
                                                                                to={
                                                                                    "/Oral"
                                                                                }
                                                                            >
                                                                                <span
                                                                                    onClick={() =>
                                                                                        setIsOpen(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    className="creative_link linko"
                                                                                >
                                                                                    Oral
                                                                                </span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li className="list-font-ss">
                                                                <Link to="Company-Profile">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        Company
                                                                        Profile
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li className="list-font-ss">
                                                                <Link to="check-authenticity">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                  Check-Authenticity
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li className="list-font-ss">
                                                                <Link to="Contact">
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="creative_link linko"
                                                                    >
                                                                        Contact
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                       
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </StyledOffCanvas>
                                            <StyledOffCanvasOverlay
                                                isOpen={isOpen}
                                                onClick={() => setIsOpen(false)}
                                            />

    </div>
  )
}

export default Head